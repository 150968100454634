import React, { useEffect } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import {
  homeOutline,
  receiptOutline,
  fishOutline,
  bulbOutline,
} from "ionicons/icons";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import Home from "./pages/Home";
import Order from "./pages/Order";
import Products from "./pages/Products";
import ProductsDetail from "./pages/ProductsDetail";
import Profile from "./pages/Profile";
import Recipes from "./pages/Recipes";
import RecipesDetail from "./pages/RecipesDetail";

import { handleBottomNavbar } from "./util/helperFunctions";

const App: React.FC = (p: any) => {
  const location = useLocation();
  useEffect(() => {
    handleBottomNavbar(location.pathname);
  }, [location.pathname]);
  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route path="/home" render={() => <Home />} exact={true} />
        <Route path="/products" render={() => <Products />} exact={true} />
        <Route path="/products/:id" component={ProductsDetail} />
        <Route path="/order" render={() => <Order />} />
        <Route path="/profile" render={() => <Profile />} />
        <Route path="/recipes" render={() => <Recipes />} exact={true} />
        <Route path="/recipes/:id" component={RecipesDetail} />
        <Route path="/" render={() => <Redirect to="/home" />} exact={true} />
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton tab="home" href="/home">
          <IonIcon style={{ transform: "scale(0.8" }} icon={homeOutline} />
          <IonLabel>HOME</IonLabel>
        </IonTabButton>
        <IonTabButton tab="products" href="/products">
          <IonIcon style={{ transform: "scale(0.8" }} icon={fishOutline} />
          <IonLabel>PRODUCTS</IonLabel>
        </IonTabButton>
        <IonTabButton tab="order" href="/order">
          <IonIcon style={{ transform: "scale(0.8" }} icon={receiptOutline} />
          <IonLabel>ORDER</IonLabel>
        </IonTabButton>
        <IonTabButton tab="recipes" href="/recipes">
          <IonIcon style={{ transform: "scale(0.8" }} icon={bulbOutline} />
          <IonLabel>RECIPES</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default App;
