// import ManropeVariableFont from "./fonts/Manrope-VariableFont.ttf";

const colors = {
  text: "#333333",
  background: "#FCECE4",
  lightRed1: "#FAD9CB",
  lightRed2: "#FCECE4",
  lightRed3: "#FEF5F2",
  lightRed4: "#FEEEEF",
  orange1: "#FC7E2F",
  orange2: "#D7421A",
  orange3: "#E66351",
  red0: "#fe6d73",
  red1: "#f08080",
  red2: "#f4978e",
  red3: "#f8ad9d",
  red4: "#fbc4ab",
  red5: "#ffdab9",
  dark: "#000000",
  gray1: "#333333",
  gray2: "#4F4F4F",
  gray3: "#828282",
  gray4: "#BDBDBD",
  gray5: "#E0E0E0",
  gray6: "#F2F2F2",
  white1: "#FFFFFF",
};

const text = {
  default: {
    fontFamily: "-apple-system, 'SFPro'",
    fontSize: "16px",
  },
  headline1: {
    fontFamily: "-apple-system, 'SFPro'",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "800",
    lineHeight: "40px",
  },
  headline2: {
    fontFamily: "-apple-system, 'SFPro'",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "40px",
  },
  headline3: {
    fontFamily: "-apple-system, 'SFPro'",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "22px",
  },
  body: {
    fontFamily: "-apple-system, 'SFPro'",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "22px",
  },
  bodySmall: {
    fontFamily: "-apple-system, 'SFPro'",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "18px",
  },
  bodyHeader: {
    fontFamily: "-apple-system, 'SFPro'",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "18px",
  },
  manchet: {
    fontFamily: "-apple-system, 'SFProRounded'",
    fontWeight: "700",
    fontSize: "11px",
    lineHeight: "14px",
    textTransform: "uppercase",
    textDecoration: "none",
  },
  button: {
    fontFamily: "-apple-system, 'SFPro'",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "24px",
  },
  text: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "800",
    lineHeight: "28px",
  },
};

const shadows = {
  buttonHover: `0px 4px 16px rgba(0, 0, 0, 0.14)`,
  element: `rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;`,
};

const buttonDefault = {
  borderRadius: "10px",
  height: "6",
  minWidth: "160px",
  px: "4",
  transition: "border-color 0.2s, background-color 0.2s, color 0.2s",
  cursor: "pointer",
  justifyContent: "center",
  "&:focus": { outline: 0 },
};

const buttons = {
  primary: {
    ...buttonDefault,
    ...text.button,
    color: "white1",
    bg: "orange3",
    "&:active": {
      bg: "red2",
      boxShadow: shadows.buttonHover,
      transition: "0s",
    },
  },
  secondary: {
    ...buttonDefault,
    ...text.button,
    color: "red1",
    bg: "lightRed4",
    "&:active": {
      bg: "background",
      color: `${colors.red2}`,
      transition: "0s",
    },
  },
};

export const radius6 = "6px";
export const radius10 = "10px";

const theme = {
  fonts: {
    // Manrope: ManropeVariableFont,
  },
  fontSizes: [],
  fontWeights: {},
  breakpoints: ["40em", "52em", "64em", "76em"],
  space: [0, 4, 8, 16, 24, 32, 48, 56, 64, 128, 256, 512],
  sizes: [0, 4, 8, 16, 24, 32, 48, 56, 64, 128, 256, 512],
  colors,
  text,
  shadows,
  buttons,
  styles: {},
};

export default theme;
