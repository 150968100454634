import React from "react";

export function Cart() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 100 100"
      viewBox="0 0 100 100"
      style={{ width: "25px" }}
    >
      <path d="M95.4 23.7c-1.8-2.3-4.6-3.7-7.5-3.7H27l-1.9-7.4c-1.1-4.3-4.9-7.3-9.3-7.3H5.6c-1.7 0-3.1 1.4-3.1 3.1s1.4 3.1 3.1 3.1h10.2c1.5 0 2.8 1 3.2 2.5l12.2 48.6c1.1 4.3 4.9 7.3 9.3 7.3h39.9c4.4 0 8.3-3 9.3-7.3l7.5-30.8c.7-2.8.1-5.8-1.8-8.1zm-4.3 6.7l-7.5 30.8c-.4 1.5-1.7 2.5-3.2 2.5H40.5c-1.5 0-2.8-1-3.2-2.5l-8.7-34.9h59.3c1 0 2 .5 2.6 1.3.6.8.9 1.8.6 2.8zM42.6 73.9c-5.7 0-10.4 4.7-10.4 10.4s4.7 10.4 10.4 10.4S53 90 53 84.3s-4.7-10.4-10.4-10.4zm0 14.5c-2.3 0-4.1-1.8-4.1-4.1s1.8-4.1 4.1-4.1 4.1 1.8 4.1 4.1c0 2.2-1.9 4.1-4.1 4.1zM77 73.9c-5.7 0-10.4 4.7-10.4 10.4S71.3 94.7 77 94.7 87.4 90 87.4 84.3c-.1-5.7-4.7-10.4-10.4-10.4zm0 14.5c-2.3 0-4.1-1.8-4.1-4.1s1.8-4.1 4.1-4.1 4.1 1.8 4.1 4.1c0 2.2-1.9 4.1-4.1 4.1z"></path>
    </svg>
  );
}

export function ArrowBack() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 98 98"
      viewBox="0 0 98 122.5"
      style={{ transform: "rotateZ(180deg)", width: "25px" }}
    >
      <path d="M85.4 46.9L56 17.5c-1.2-1.2-3.1-1.2-4.2 0-1.2 1.2-1.2 3.1 0 4.2L76.1 46H14.7c-1.7 0-3 1.3-3 3s1.3 3 3 3h61.4L51.8 76.3c-1.2 1.2-1.2 3.1 0 4.2.6.6 1.4.9 2.1.9s1.5-.3 2.1-.9l29.4-29.4c1.2-1.2 1.2-3.1 0-4.2z"></path>
    </svg>
  );
}

export function EmptyCartIcon({ style }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="279"
      height="271"
      fill="none"
      viewBox="0 0 279 271"
      style={style}
    >
      <path
        fill="#FBC4AB"
        stroke="#000"
        strokeWidth="6"
        d="M276 121H3c0 48.184 23.79 93.26 63.571 120.448L72.5 245.5h138l3.166-2.296A150.956 150.956 0 00276 121z"
      ></path>
      <path
        fill="#F08080"
        stroke="#000"
        strokeLinejoin="round"
        strokeWidth="5"
        d="M73 268.5V247h137v21.5H73z"
      ></path>
      <circle cx="99" cy="176" r="9" fill="#000"></circle>
      <circle cx="181" cy="176" r="9" fill="#000"></circle>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="6"
        d="M127.5 208.5h26M73 48l16 35M131 4l4 59M210 44l-18 39"
      ></path>
    </svg>
  );
}
