import React from "react";

import { Box } from "theme-ui";
import theme from "../../theme/theme";

export const safePadding = "3";
export const safePaddingPx = `${theme.space[Number(safePadding)]}px`;

const Safezone = ({
  children,
  xOnly = false,
}: {
  children: any;
  xOnly?: boolean;
}) => {
  return (
    <Box p={safePadding} py={xOnly ? 0 : safePadding}>
      {children}
    </Box>
  );
};

export default Safezone;
