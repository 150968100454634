import React from "react";

import { Box, Flex, Text } from "theme-ui";
import { useHistory } from "react-router-dom";
import { safePadding } from "./Safezone";

type CardWrapperProps = {
  children: any;
  left: string;
  right?: string;
  link?: string;
  withSafeZone?: boolean;
};

const CardWrapper = ({
  children,
  left,
  right,
  link,
  withSafeZone,
}: CardWrapperProps) => {
  const history = useHistory();

  return (
    <Box mt="5">
      <Flex
        pb="2"
        px={withSafeZone ? safePadding : 0}
        sx={{ justifyContent: "space-between" }}
      >
        <Text variant="manchet">{left}</Text>
        {link && (
          <Text
            onClick={() => {
              history.push(link);
            }}
            variant="manchet"
            sx={{ color: "red0" }}
          >
            {right}
          </Text>
        )}
      </Flex>
      {children}
    </Box>
  );
};

export default CardWrapper;
