import React from "react";

import { Box, Flex, Text } from "theme-ui";
import ReloadLogo from "../../assets/vector/logo";

const FeaturedContent: React.FC = () => {
  return (
    <Box mt="3">
      <Text sx={{ textAlign: "center" }} variant="headline1">
        FOODIE
      </Text>
      <Flex sx={{ justifyContent: "center" }}>
        <ReloadLogo />
      </Flex>
    </Box>
  );
};

export default FeaturedContent;
