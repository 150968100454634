import React, { useState } from "react";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  IonAlert,
} from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../store/reducers";
import { Product } from "./Products";
import { Box, Flex, Grid, Image, Text } from "theme-ui";
import theme from "../theme/theme";
import { addOutline, removeOutline, trashOutline } from "ionicons/icons";
import { useHistory } from "react-router";
import { EmptyCartIcon } from "../assets/vector/icon";

const Order: React.FC = () => {
  const [showAlert, setAlert] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { cart, products } = useSelector((store: RootStore) => ({
    cart: store.cart.cart,
    products: store.data.products,
  }));
  const cartItems = Object.values(cart);

  const handleOnAdd = (productId: string) => {
    dispatch({
      type: "ADD_TO_CART",
      payload: productId,
    });
  };
  const handleOnRemove = (productId: string) => {
    dispatch({
      type: "REMOVE_FROM_CART",
      payload: productId,
    });
  };
  const handleOnClear = () => {
    dispatch({
      type: "CLEAR_CART",
    });
  };

  return (
    <IonPage>
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>
            Cart{cartItems.length > 0 && ` (${cartItems.length})`}
          </IonTitle>
          <IonButtons slot="end">
            <Box
              p="3"
              onClick={() => {
                setAlert(true);
              }}
            >
              <IonIcon
                icon={trashOutline}
                style={{ transform: "scale(1.5)" }}
              />
            </Box>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setAlert(false)}
          header={"Clear cart"}
          message={"Do you wanna clear all items from your cart?"}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
            },
            {
              text: "Clear",
              handler: handleOnClear,
            },
          ]}
        />
        <EmptyCart show={cartItems.length === 0} />
        <Box>
          {cartItems.map(({ productId, amount }) => {
            const product = products.find((p) => p.id === productId);
            if (!product) return null;
            return (
              <CartItem
                key={product.id}
                product={product}
                amount={amount}
                onAddProduct={() => handleOnAdd(product.id)}
                onRemoveProduct={() => handleOnRemove(product.id)}
                onGoToProduct={() =>
                  history.push(`/products/${product.id}`, { product })
                }
              />
            );
          })}
        </Box>
      </IonContent>
    </IonPage>
  );
};

type CartItemProps = {
  product: Product;
  onGoToProduct: () => void;
  amount: number;
  onAddProduct: () => void;
  onRemoveProduct: () => void;
};
const CartItem = ({
  product,
  onGoToProduct,
  onAddProduct,
  onRemoveProduct,
  amount,
}: CartItemProps) => {
  return (
    <Grid
      p="4"
      gap="5"
      sx={{
        gridTemplateColumns: "auto 1fr",
        borderBottom: `1px solid ${theme.colors.gray6}`,
      }}
    >
      <Box sx={{ width: "70px" }} onClick={onGoToProduct}>
        <Image src={product.picture_list} />
      </Box>
      <Box>
        <Flex sx={{ justifyContent: "space-between" }}>
          <Box>
            <Text variant="headline3">{product.title}</Text>
            <Text variant="bodySmall" sx={{ color: "gray3" }}>
              {product.sub_title}
            </Text>
          </Box>
          <Text
            variant="headline3"
            sx={{
              fontVariantNumeric: "tabular-nums",
            }}
          >
            ${(Number(product.price) * amount).toFixed(2)}
          </Text>
        </Flex>
        <Flex mt="3" sx={{ alignItems: "center" }}>
          <IconWrapper onClick={onAddProduct}>
            <IonIcon icon={addOutline} />
          </IconWrapper>
          <Text mx="3">{amount}</Text>
          <IconWrapper onClick={onRemoveProduct}>
            <IonIcon icon={removeOutline} />
          </IconWrapper>
        </Flex>
      </Box>
    </Grid>
  );
};

type IconWrapperProps = {
  children: any;
  onClick: () => void;
};
const IconWrapper = ({ children, onClick }: IconWrapperProps) => {
  return (
    <Flex
      onClick={onClick}
      p="2"
      sx={{
        border: `1px solid ${theme.colors.gray6}`,
        justifyContent: "center",
        alignItems: "center",
        width: "40px",
        height: "40px",
        transition: "0.3s",
        "&:active": {
          backgroundColor: theme.colors.lightRed3,
          border: `1px solid ${theme.colors.lightRed3}`,
          transition: "0s background-color",
          boxShadow: theme.shadows.element,
        },
      }}
    >
      {children}
    </Flex>
  );
};

const Circle = ({ size, bg }: { size: string; bg: string }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        width: `${size}vw`,
        height: `${size}vw`,
        backgroundColor: bg,
        borderRadius: "100%",
        opacity: 0.4,
      }}
    />
  );
};

const EmptyCart = ({ show }: { show: boolean }) => {
  if (!show) {
    return null;
  }
  return (
    <Flex
      sx={{ justifyContent: "center", flexDirection: "column", height: "100%" }}
    >
      <Box sx={{ justifyContent: "center" }}>
        <Text variant="headline2" sx={{ textAlign: "center" }}>
          Your basket is empty
        </Text>
        <Text variant="bodyHeader" sx={{ textAlign: "center" }}>
          Go ahead and add some items
        </Text>
      </Box>
      <Flex
        sx={{
          width: "100vw",
          height: "100vw",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Circle size="80" bg="red5" />
        <Circle size="55" bg="red2" />
        <Circle size="25" bg="red0" />
        <EmptyCartIcon style={{ width: "30vw", position: "relative" }} />
      </Flex>
    </Flex>
  );
};

export default React.memo(Order);
