import React from "react";

import { Box, Text } from "theme-ui";

type BadgeProps = { children: any; text: string };

const Badge = ({ children, text }: BadgeProps) => {
  return (
    <Box sx={{ position: "relative" }}>
      {children}
      <Box
        as="span"
        px="2"
        sx={{
          pointerEvents: "none",
          borderRadius: "10px",
          backgroundColor: "red0",
          position: "absolute",
          right: "-5px",
          color: "rgba(255, 255, 255, 0.8)",
          textTransform: "uppercase",
        }}
      >
        <Text variant="manchet" sx={{ fontSize: "9px" }}>
          {text}
        </Text>
      </Box>
    </Box>
  );
};

export default Badge;
