import React, { useState } from "react";

import { IonAlert, IonContent, IonIcon, IonPage } from "@ionic/react";
import { Box, Button, Flex, Grid, Image, Text } from "theme-ui";
import Safezone from "../components/ui/Safezone";
import theme, { radius10 } from "../theme/theme";
import { alarmOutline, fastFoodOutline, peopleOutline } from "ionicons/icons";

import { Recipe } from "./Recipes";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../store/reducers";
import { useHistory } from "react-router";
import { ArrowBack } from "../assets/vector/icon";
import Badge from "../components/ui/Badge";

const standard = {
  id: "1",
  title: "Keralan veggie curry",
  product_id: "6, 14, 15",
  description:
    "Remove the outer leaves from the cauliflower, then slice it 1cm thick and put it on the griddle pan, turning when lightly charred. Put 1 mug of rice and 2 mugs of boiling water, lemon half and a pinch of sea salt. Pour 2 tablespoons of oil into the casserole pan, then stir in the mustard and fenugreek seeds, turmeric and curry leaves. Peel the ginger and garlic cloves, and trim the spring onions. Pulse these, together with the chilli and coriander stalks in the processor until fine, then stir into the casserole pan. Roughly chop and add the peppers. Pour in the coconut milk, drain and add the chickpeas, then tip in the pineapple chunks and their juices. Add the griddled cauliflower, cover, turn the heat up to high and bring to the boil. Tear off the top leafy half of the mint and bash to a paste in a pestle and mortar. Stir in the yoghurt, add a good squeeze of lemon juice and season with salt and black pepper. Squeeze the juice of the remaining lemon into the curry and season to taste. Sprinkle over the coriander leaves and serve with the rice and poppadoms.",
  picture:
    "https://img.jamieoliver.com/jamieoliver/recipe-database/oldImages/large/850_1_1436804730.jpg?tr=w-800,h-1066",
} as Recipe;

const RecipesDetail = (props: any) => {
  const recipeProps: Recipe | undefined = props.location.state?.recipe;

  const dispatch = useDispatch();
  const [showAlert, setAlert] = useState(false);
  const [recipe] = useState<Recipe>(recipeProps || standard);
  const products = useSelector((store: RootStore) => store.data.products);
  const history = useHistory();

  const productsForRecipe = recipe.product_id
    .split(",")
    .map((p) => p.trim())
    .map((p) => products[Number(p) - 1]);

  const handleAddToCart = () => {
    productsForRecipe.forEach((p) => {
      dispatch({
        type: "ADD_TO_CART",
        payload: p.id,
      });
    });
    setAlert(true);
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const handleGoToCart = () => {
    history.push("/order");
  };

  const cooking = [
    {
      icon: alarmOutline,
      tStart: "Duration",
      tValue: `1 hour ${recipe.title.length} min`,
      tEnd: "",
    },
    {
      icon: peopleOutline,
      tStart: "Portion size",
      tValue: recipe.title.split(" ").length,
      tEnd: "people",
    },
    {
      icon: fastFoodOutline,
      tStart: "Kcal",
      tValue: recipe.description.length,
      tEnd: "",
    },
  ];

  return (
    <IonPage>
      <IonContent fullscreen style={{ position: "relative" }}>
        <BarTop goBack={handleGoBack} />
        <Image
          sx={{
            width: "100%",
            height: "50vh",
            objectFit: "cover",
          }}
          src={recipe.picture}
        />
        <Safezone>
          <Box>
            <Text variant="headline2" sx={{ textTransform: "capitalize" }}>
              {recipe.title}
            </Text>
            <Text
              variant="body"
              sx={{ color: "gray3", height: "180px", overflow: "hidden" }}
            >
              {recipe.description}
            </Text>
          </Box>

          <Box mt="5">
            <Text variant="headline3" mb="3">
              Ingredients
            </Text>
            <Grid
              sx={{
                gridTemplateColumns: "1fr 1fr 1fr",
                alignItems: "flex-end",
              }}
            >
              {productsForRecipe.map((p) => (
                <Box>
                  <Badge text={`${p.title.length}0 gram`}>
                    <Image
                      src={p.picture_list}
                      sx={{
                        width: `70%`,
                        height: "50px",
                        objectFit: "contain",
                        borderRadius: radius10,
                      }}
                      onClick={() => {
                        history.push(`/products/${p.id}`, { product: p });
                      }}
                    />
                  </Badge>
                  <Text variant="bodyHeader">{p.title}</Text>
                  <Text variant="bodySmall">{p.sub_title}</Text>
                </Box>
              ))}
            </Grid>
          </Box>

          <Box mt="5" mb="5">
            <Text mb="3" variant="headline3">
              Cooking
            </Text>
            {cooking.map((row) => (
              <Flex sx={{ alignItems: "center" }} mt="2">
                <IonIcon
                  icon={row.icon}
                  size="large"
                  style={{ transform: "scale(0.8)" }}
                />
                <Text
                  variant="body"
                  ml="2"
                  sx={{ display: "flex", color: "gray3" }}
                >
                  {row.tStart}
                  <Box mr="1">:</Box>
                  <Text variant="body" sx={{ color: "gray3" }}>
                    {" "}
                    {`${row.tValue} `}
                  </Text>
                  <Box mr="1" />
                  {row.tEnd}
                </Text>
              </Flex>
            ))}
          </Box>
        </Safezone>
        <BarBottom onAddToCart={handleAddToCart} />
        <IonAlert
          isOpen={showAlert}
          onDidDismiss={() => setAlert(false)}
          header={"Ingredients added"}
          message={`The ingredients for '${recipe.title}' is added to you cart!`}
          buttons={[
            {
              text: "Stay",
              role: "cancel",
            },
            {
              text: "Go to cart",
              handler: handleGoToCart,
            },
          ]}
        />
      </IonContent>
    </IonPage>
  );
};

type BarTopProps = {
  goBack: () => void;
};
function BarTop({ goBack }: BarTopProps) {
  return (
    <Box
      pt="3"
      sx={{
        backdropFilter: "blur(40px)",
        color: "white",
        fill: "white",
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 2,
        boxShadow: theme.shadows.buttonHover,
      }}
    >
      <Safezone xOnly>
        <Flex
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            height: "60px",
          }}
        >
          <Flex sx={{ width: "40px", height: "40px" }} onClick={goBack}>
            <ArrowBack />
          </Flex>
          <Text sx={{ fontWeight: "600" }}>Recipe</Text>
          <Flex sx={{ width: "40px", height: "40px" }} />
        </Flex>
      </Safezone>
    </Box>
  );
}

type BarBottomProps = {
  onAddToCart: () => void;
};

function BarBottom({ onAddToCart }: BarBottomProps) {
  return (
    <Box
      p="3"
      pb="4"
      mt="4"
      sx={{
        position: "fixed",
        zIndex: "1",
        bottom: "0px",
        backgroundColor: "white",
        borderTop: `1px solid ${theme.colors.gray6}`,
        boxShadow: theme.shadows.element,
        width: "100%",
      }}
    >
      <Button
        variant="primary"
        sx={{ width: "100%" }}
        onClick={() => {
          onAddToCart();
        }}
      >
        Add ingredients to basket
      </Button>
    </Box>
  );
}

export default React.memo(RecipesDetail);
