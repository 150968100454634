import React from "react";

import { IonIcon } from "@ionic/react";
import { Flex, Text } from "theme-ui";
import theme, { radius6 } from "../../theme/theme";
import { heartOutline } from "ionicons/icons";
import { Recipe } from "../../pages/Recipes";

type CardSmallProps = {
  recipe: Recipe;
  onGoToRecipe: () => void;
};

function CardSmall({ recipe, onGoToRecipe }: CardSmallProps) {
  return (
    <Flex
      onClick={onGoToRecipe}
      sx={{
        backgroundSize: "cover",
        backgroundImage: `url("${recipe.picture}")`,
        backgroundPosition: "center center",
        height: `${window.innerWidth / 1.5}px`,
        borderRadius: "2px",
        flexDirection: "column",
        justifyContent: "space-between",
        boxShadow: theme.shadows.buttonHover,
        overflow: "hidden",
      }}
    >
      <Flex p="3" sx={{ justifyContent: "flex-end" }}>
        <IonIcon
          style={{ transform: "scale(1.5", color: "white" }}
          icon={heartOutline}
        />
      </Flex>
      <Text
        p="2"
        variant="manchet"
        sx={{
          color: "white",
          backdropFilter: "blur(40px)",
          borderRadius: radius6,
        }}
      >
        {recipe.title}
      </Text>
    </Flex>
  );
}

export default CardSmall;
