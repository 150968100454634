import React from "react";
import theme from "../../theme/theme";

function ReloadLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="20"
      viewBox="0 0 1703.6 400"
      style={{ fill: theme.colors.gray4 }}
    >
      <path d="M240.3 165.7c0-17.9-6.3-32.7-18.8-44.6s-27.6-18.2-45.5-18.2H99V301h35.5v-70.5h35.2l42.4 70.5h40.1l-48-79c21.6-9.1 36.1-30.5 36.1-56.3zm-67.9 33h-37.8V135h40.6c17.1 0 29.8 13.1 29.8 31.3s-13.3 32.4-32.6 32.4z"></path>
      <path d="M354 301L481.6 301 481.6 268.8 389.5 268.8 389.5 214.3 481.6 214.3 481.6 182.2 389.5 182.2 389.5 135 481.6 135 481.6 102.9 354 102.9 354 301z"></path>
      <path d="M644.8 102.9L609.3 102.9 609.3 301 736.9 301 736.9 268.8 644.8 268.8 644.8 102.9z"></path>
      <path d="M1203.3 102.9L1120.6 301h37.5l16.2-39.5h88.1l16.2 39.5h37.5l-82.7-198.1zm-17.9 129.3l33-80.7 33 80.7zM1577 130.4c-18.2-18.5-43.8-27.6-76.4-27.6h-70.8V301h71c32.7 0 58.3-9.1 76.4-27s27.3-41.8 27.3-71.6-9.1-53.8-27.5-72zm-78.7 138.4h-33V135h32.7c49.7 0 71 25.6 71 67.4s-21.6 66.4-70.8 66.4zM984.4 166.8a67.1 67.1 0 11-57.3-32.2l-20.7 20.7 20.7 20.7 38.5-38.5 20.7-20.7-59.2-59.2-20.7 20.7L927.1 99a102.5 102.5 0 1087.7 49.3z"></path>
    </svg>
  );
}

export default ReloadLogo;
