import React, { useState } from "react";
import {
  IonAvatar,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import { Box, Text } from "theme-ui";
import Safezone from "../components/ui/Safezone";
import { useSelector } from "react-redux";
import { RootStore } from "../store/reducers";
import { motion } from "framer-motion";

const IonListMotion = motion.custom(IonList);
const IonItemMotion = motion.custom(IonItem);

export type Product = {
  id: string;
  title: string;
  sub_title: string;
  description: string;
  category: string;
  tags: string;
  picture_list: string;
  picture_detail: string;
  picture_preview: string;
  price: string;
  pricePretty: string;
  flip: any;
};

const Products: React.FC = () => {
  const products = useSelector((store: RootStore) => store.data.products);
  const [isVisible, setVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("Fruits");
  const history = useHistory();
  useIonViewDidEnter(() => {
    setVisible(true);
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Products</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Products</IonTitle>
          </IonToolbar>
        </IonHeader>

        <Safezone>
          <Box>
            <IonSegment
              value={selectedCategory}
              onIonChange={(e) => setSelectedCategory(e.detail.value as string)}
            >
              <IonSegmentButton value="Fruits">
                <IonLabel>Fruits</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="Vegetables">
                <IonLabel>Vegetables</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </Box>
        </Safezone>
        <IonListMotion
          variants={variantsList}
          animate={isVisible ? "open" : "closed"}
          initial={false}
        >
          {products
            .filter((i) => i.category === selectedCategory)
            .map((product) => (
              <IonItemMotion
                key={product.id}
                onClick={() => {
                  history.push(`/products/${product.id}`, { product });
                }}
                variants={variantsItem}
              >
                <IonAvatar slot="start">
                  <img src={product.picture_list} alt="" />
                </IonAvatar>
                <IonLabel>
                  <Text>{product.title}</Text>
                  <Text sx={{ color: "gray4" }}>{product.sub_title}</Text>
                </IonLabel>
                <IonLabel slot="end">
                  <Text sx={{ textAlign: "end" }}>{product.pricePretty}</Text>
                </IonLabel>
              </IonItemMotion>
            ))}
        </IonListMotion>
      </IonContent>
    </IonPage>
  );
};

const variantsList = {
  open: {
    transition: { staggerChildren: 0.05 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const variantsItem = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      x: { type: "inertia" },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      x: { type: "inertia" },
    },
  },
};

export default React.memo(Products);
