import React from "react";

import { IonContent, IonPage } from "@ionic/react";
import { Grid } from "theme-ui";
import CardSmall from "../components/ui/CardSmall";
import { useSelector } from "react-redux";
import { RootStore } from "../store/reducers";
import { useHistory } from "react-router";

export type Recipe = {
  id: string;
  product_id: string;
  title: string;
  description: string;
  picture: string;
  picture_preview: string;
  popular: string;
};

const Recipes: React.FC = () => {
  const recipes = useSelector((store: RootStore) => store.data.recipes);
  const history = useHistory();

  const handleGoToRecipe = (id: string, recipe: Recipe) => {
    history.push(`/recipes/${id}`, { recipe });
  };
  return (
    <IonPage>
      <IonContent fullscreen>
        <Grid mt="2" mx="2" sx={{ gridTemplateColumns: "1fr 1fr" }} gap="2">
          {recipes.map((recipe) => (
            <CardSmall
              key={recipe.id}
              recipe={recipe}
              onGoToRecipe={() => handleGoToRecipe(recipe.id, recipe)}
            />
          ))}
        </Grid>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Recipes);
