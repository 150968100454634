import React, { useState } from "react";

import { Box, Image, SxStyleProp } from "theme-ui";

type ImageProgressiveProps = {
  url: string;
  urlPreview: string;
  sxBox?: SxStyleProp;
  sxImage?: SxStyleProp;
};

let imagesCache = [] as any;

const ImageProgressive = ({
  url,
  urlPreview,
  sxImage,
  sxBox,
}: ImageProgressiveProps) => {
  const fromCache = Number(imagesCache.includes(url));
  const [isLoaded, setLoaded] = useState(fromCache);

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        ...sxBox,
      }}
    >
      <Image
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          transform: "scale(1.1)",
          filter: "blur(10px)",
          ...sxImage,
        }}
        src={urlPreview}
      />
      <Image
        sx={{
          position: "relative",
          transition: "0.3s opacity",
          opacity: Number(isLoaded),
          ...sxImage,
        }}
        onLoad={() => {
          if (fromCache) return;
          // Pushing to cache if isn't
          imagesCache.push(url);
          setLoaded(1);
        }}
        src={url}
      />
    </Box>
  );
};

export default ImageProgressive;
