import { createStore, compose, combineReducers } from "redux";
import reducers from "./reducers";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

// Connecting to redux devtool extension if available
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(combineReducers(reducers), composeEnhancers());

// Exposing a dispatch function if needed outside a React component
export const dispatch = store.dispatch as (action: {
  type: string;
  payload?: any;
}) => void;

export default store;
