import React from "react";

import { Box, Flex } from "theme-ui";
import theme from "../../theme/theme";
import { useSelector } from "react-redux";
import { RootStore } from "../../store/reducers";

const counterWidth = 15;
const counterWidthPx = `${counterWidth}px`;

function Counter({ children }: any) {
  const cart = useSelector((store: RootStore) => store.cart.cart);
  const cartAmount = Object.values(cart).reduce((acc, i) => acc + i.amount, 0);
  return (
    <Flex
      sx={{
        position: "relative",
      }}
    >
      {children}
      <Flex
        sx={{
          position: "absolute",
          right: "-10px",
          justifyContent: "center",
          width: counterWidthPx,
          height: counterWidthPx,
          borderRadius: "100%",
          overflow: "hidden",
          backgroundColor: theme.colors.red1,
        }}
      >
        <Box
          sx={{
            transform: `translateY(-${
              (cartAmount > 20 ? 20 : cartAmount) * counterWidth
            }px)`,
            transition: "0.3s",
          }}
        >
          {Array(21)
            .fill(true)
            .map((_, i) => (
              <Flex
                key={i}
                sx={{
                  height: counterWidthPx,
                  width: counterWidthPx,
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: `${cartAmount > 20 ? "7px" : "10px"}`,
                }}
              >
                {`${cartAmount > 20 ? "+20" : i}`}
              </Flex>
            ))}
        </Box>
      </Flex>
    </Flex>
  );
}

export default Counter;
