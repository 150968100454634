import React from "react";
import { IonIcon } from "@ionic/react";

import { Box, Flex, Text } from "theme-ui";
import theme, { radius10 } from "../../theme/theme";
import { giftOutline } from "ionicons/icons";

import img from "../../assets/promoted_art.jpg";

const PromotedContent = () => {
  return (
    <Flex
      px="3"
      sx={{
        justifyContent: "space-betweem",
        alignItems: "center",
        height: "80px",
        borderRadius: radius10,
        color: "white",
        boxShadow: theme.shadows.element,
        position: "relative",
        overflow: "hidden",
        backgroundImage: `url(${img})`,
      }}
    >
      <Box sx={{ flex: 1 }} id="xxxx">
        <Text variant="headline3" sx={{}}>
          Gift from Reload
        </Text>
        <Text variant="bodyHeader" sx={{}}>
          Use promo code 'FOODIE{new Date().getFullYear()}'
        </Text>
      </Box>
      <IonIcon
        size="large"
        style={{ transform: "scale(0.8" }}
        icon={giftOutline}
      />
    </Flex>
  );
};

export default PromotedContent;
