import React from "react";

import { Box, Text } from "theme-ui";

const Chip = ({ children }: { children: any }) => {
  return (
    <Box
      as="span"
      px="3"
      py="1"
      mr="2"
      sx={{
        borderRadius: "4px",
        backgroundColor: "red1",
        color: "rgba(255, 255, 255, 0.8)",
        textTransform: "capitalize",
      }}
    >
      <Text variant="bodySmall" sx={{}}>
        {children}
      </Text>
    </Box>
  );
};

export default Chip;
