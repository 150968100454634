import { matchPath } from "react-router";

export function handleBottomNavbar(pathname: string) {
  const hideNavbar: string[] = ["/products/:id", "/recipes/:id"];

  const HTML_navbarBottom = window.document.querySelector("ion-tab-bar");
  if (!HTML_navbarBottom) {
    return;
  }

  if (
    hideNavbar.some((pathHide) =>
      matchPath(pathname, {
        path: pathHide,
        exact: true,
        strict: true,
      })
    )
  ) {
    // HTML_navbarBottom.setAttribute(
    //   "style",
    //   `position: absolute; transition: 0.3s transform; transform: translateY(100%); width: 100%; bottom: 0px;`
    // );
    HTML_navbarBottom.setAttribute(
      "style",
      "transform: translateY(100%); transition: 0.3s"
    );
  } else {
    // HTML_navbarBottom.setAttribute(
    //   "style",
    //   `transition: 0.3s transform; transform: translateY(0px)`
    // );
    HTML_navbarBottom.setAttribute(
      "style",
      "transform: translateY(0px); transition: 0.3s"
    );
  }
}
