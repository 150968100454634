import React from "react";

import { useSelector } from "react-redux";
import { RootStore } from "../../store/reducers";
import HorizontalSlider from "../../components/ui/HorizontalSlider";
import { Product } from "../Products";
import { useHistory } from "react-router";
import { Recipe } from "../Recipes";

export const PopularContentRecipe: React.FC = () => {
  const history = useHistory();
  const recipes = useSelector((store: RootStore) => store.data.recipes).filter(
    (recipe) => recipe.popular
  );
  const handleClick = (recipe: Recipe) => {
    history.push(`/recipes/${recipe.id}`, { recipe });
  };

  return (
    <HorizontalSlider
      items={recipes.map((recipe) => ({
        id: recipe.id,
        image: recipe.picture,
        header: recipe.title,
        description: recipe.title,
        onClick: () => handleClick(recipe),
      }))}
    />
  );
};

export const PopularContentProducts: React.FC = () => {
  const history = useHistory();
  const products = useSelector((store: RootStore) => store.data.products);
  const productsCopy: Product[] = JSON.parse(JSON.stringify(products))
    .reverse()
    .slice(0, 8);
  const handleClick = (product: Product) => {
    history.push(`/products/${product.id}`, { product });
  };

  return (
    <HorizontalSlider
      items={productsCopy.map((product) => ({
        id: product.id,
        image: product.picture_detail,
        header: product.title,
        description: product.sub_title,
        onClick: () => handleClick(product),
      }))}
    />
  );
};
