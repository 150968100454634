import React from "react";

import { Box, Flex, Text } from "theme-ui";
import { radius6 } from "../../theme/theme";
import recipeSelected from "../../data/recipes.json";
import { useHistory } from "react-router";

function Bagde() {
  return (
    <Text
      variant="manchet"
      px="10px"
      py="2px"
      sx={{
        display: "inline-flex",
        backgroundColor: "orange2",
        borderRadius: radius6,
      }}
    >
      NEW RECIPE
    </Text>
  );
}

const FeaturedContent: React.FC = () => {
  const recipe = recipeSelected[7];
  const history = useHistory();
  return (
    <Flex
      p="3"
      onClick={() => {
        history.push(`/recipes/${recipe.id}`, { recipe });
      }}
      sx={{
        height: `${window.innerWidth * 0.6}px`,
        flexDirection: "column",
        justifyContent: "space-between",
        borderRadius: radius6,
        color: "white",
        backgroundImage: `url(${recipe.picture})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Box>
        <Bagde />
      </Box>
      <Box>
        <Text
          variant="headline2"
          sx={{ textTransform: "uppercase", fontSize: "20px" }}
        >
          {recipe.title}
        </Text>
        <Text variant="bodyHeader">By Mathias Piletti</Text>
      </Box>
    </Flex>
  );
};

export default FeaturedContent;
