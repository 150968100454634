import React from "react";
import { IonContent, IonPage } from "@ionic/react";

import FeaturedContent from "./Home/FeaturedContent";
import Topbar from "./Home/Topbar";
import Safezone from "../components/ui/Safezone";
import CardWrapper from "../components/ui/CardWrapper";
import {
  PopularContentRecipe,
  PopularContentProducts,
} from "./Home/PopularContent";
import PromotedContent from "./Home/PromotedContent";
import { Box } from "theme-ui";

const Home: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <Topbar />
        <Safezone xOnly>
          <CardWrapper left="featured content">
            <FeaturedContent />
          </CardWrapper>
        </Safezone>
        <Safezone xOnly>
          <CardWrapper left="Promoted">
            <PromotedContent />
          </CardWrapper>
        </Safezone>
        <CardWrapper
          left="recipes"
          right="view all"
          link="/recipes"
          withSafeZone
        >
          <PopularContentRecipe />
        </CardWrapper>
        <CardWrapper
          left="products"
          right="view all"
          link="/products"
          withSafeZone
        >
          <PopularContentProducts />
        </CardWrapper>
        <Box p="3" />
      </IonContent>
    </IonPage>
  );
};

export default React.memo(Home);
