import React from "react";

import { Box, Flex, Text } from "theme-ui";
import { safePaddingPx } from "../../components/ui/Safezone";
import theme, { radius10 } from "../../theme/theme";

type HorizontalSliderProps = {
  items: Item[];
};

type Item = {
  id: string;
  image: string;
  header: string;
  description: string;
  onClick: () => void;
};

export const CARD_WIDTH = window.innerWidth / 3;

const HorizontalSlider = ({ items }: HorizontalSliderProps) => {
  return (
    <Flex
      pb="2"
      sx={{
        overflowX: "scroll",
        scrollSnapType: "x mandatory",
        scrollPaddingLeft: safePaddingPx,
        scrollPaddingRight: safePaddingPx,
        flex: 1,
      }}
    >
      {items.map((item) => {
        const { id, image, header, description, onClick } = item;
        const width = CARD_WIDTH;

        return (
          <Box
            key={id}
            mx="2"
            sx={{
              display: "block",
              minWidth: `${width}px`,
              scrollSnapAlign: "start",
              "&:first-of-type": {
                marginLeft: safePaddingPx,
              },
              "&:last-child": {
                marginRight: safePaddingPx,
              },
            }}
            onClick={onClick}
          >
            <Box
              mb="2"
              sx={{
                width: `${width}px`,
                height: `${width * 0.7}px`,
                borderRadius: radius10,
                backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                boxShadow: theme.shadows.element,
              }}
            />
            <Text variant="bodyHeader">{header}</Text>
            <Text
              variant="bodySmall"
              sx={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {description}
            </Text>
          </Box>
        );
      })}
    </Flex>
  );
};

export default HorizontalSlider;
