import produce from "immer";
import { Product } from "../pages/Products";
import { Recipe } from "../pages/Recipes";

import dataProducts from "../data/products.json";
import dataRecipes from "../data/recipes.json";

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Action {
  payload: any;
  type: string;
}

/**  ------------- Data -------------- */

const initialData = {
  products: dataProducts,
  recipes: dataRecipes,
};

type Data = {
  products: Product[];
  recipes: Recipe[];
};

/**  ------------- Cart ------------- */

const initialCart = {
  cart: {},
};

type Cart = {
  cart: {
    [key: string]: {
      productId: string;
      amount: number;
    };
  };
};

/**
 *  Reducers
 */
const data = produce((draft: Data, action: Action) => {
  const { type, payload } = action;
  switch (type) {
    case "SET_PRODUCTS":
      draft.products = payload;
      return draft;
    case "SET_RECIPES":
      draft.recipes = payload;
      return draft;
  }
}, initialData);

const cart = produce((draft: Cart, action: Action) => {
  const { type, payload } = action;
  switch (type) {
    case "ADD_TO_CART":
      if (!draft.cart[payload]) {
        draft.cart[payload] = {
          productId: payload,
          amount: 1,
        };
      } else {
        draft.cart[payload].amount += 1;
      }
      return draft;
    case "REMOVE_FROM_CART":
      if (draft.cart[payload]?.amount > 1) {
        draft.cart[payload].amount -= 1;
      } else {
        delete draft.cart[payload];
      }
      return draft;
    case "CLEAR_CART":
      draft.cart = {};
      return draft;
  }
}, initialCart);

export interface RootStore {
  data: Data;
  cart: Cart;
}

const reducers = {
  data,
  cart,
};

export default reducers;
