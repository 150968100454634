import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ThemeProvider } from "theme-ui";

import * as serviceWorker from "./serviceWorker";
import theme from "./theme/theme";
import "./theme/globals.css";
import { IonApp, setupConfig } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Provider as ReduxProvider } from "react-redux";

import store from "./store/store";

/**
 * // Pulling data from Google Sheets
 * https://docs.google.com/spreadsheets/d/1y6mqNoYJsZNlKH1Ia0Ip-rwEUbo5VCKFq0UaWm4kZ7o/edit?ts=5ff9e536#gid=0
 * import "./api/api";
 */

setupConfig({
  // Disabling swipe back, because its currently unstable
  swipeBackEnabled: false,
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <IonApp>
      <IonReactRouter>
        <ReduxProvider store={store}>
          <App />
        </ReduxProvider>
      </IonReactRouter>
    </IonApp>
  </ThemeProvider>,
  document.getElementById("root")
);

serviceWorker.register();
